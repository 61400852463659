import random from 'lodash/random'

// Array of available nodes to connect to
// const nodes = [process.env.REACT_APP_NODE_1, process.env.REACT_APP_NODE_2, process.env.REACT_APP_NODE_3]
const nodes = [
  process.env.REACT_APP_NODE_1,
  process.env.REACT_APP_NODE_2,
  process.env.REACT_APP_NODE_3,
  process.env.REACT_APP_NODE_4,
  process.env.REACT_APP_NODE_5,
  process.env.REACT_APP_NODE_6,
  process.env.REACT_APP_NODE_7,
  process.env.REACT_APP_NODE_8,
  process.env.REACT_APP_NODE_9,
  process.env.REACT_APP_NODE_10,
  process.env.REACT_APP_NODE_11,
  process.env.REACT_APP_NODE_12,
].filter((node) => node !== undefined && node !== null)
// const nodes = [
//   "https://bsc-dataseed1.binance.org",
//   "https://bsc-dataseed2.binance.org",
//   "https://bsc-dataseed3.binance.org",
//   "https://bsc-dataseed4.binance.org",
//   "https://bsc-dataseed1.defibit.io",
//   "https://bsc-dataseed2.defibit.io",
//   "https://bsc-dataseed3.defibit.io",
//   "https://bsc-dataseed4.defibit.io",
//   "https://bsc-dataseed1.ninicoin.io",
//   "https://bsc-dataseed2.ninicoin.io",
//   "https://bsc-dataseed3.ninicoin.io",
//   "https://bsc-dataseed4.ninicoin.io",
// ]

const getNodeUrl = () => {
  const randomIndex = random(0, nodes.length - 1)
  return nodes[randomIndex]
}

export default getNodeUrl
